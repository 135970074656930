import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { m } from "framer-motion";
import { fadeIn } from "../../Functions/GlobalAnimations";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { ProgressBarData01 } from "../../Components/ProgressBar/ProgressBarData";
import Banner from "../../Components/Banner/Banner";

const QualityControl = () => {
  return (
    <div>
      <Banner
        img="/assets/img/Banner/quality-control-banner.webp"
        title="Quality Control"
        description="Technologies & Innovation Well Involved For Better Production"
      />
      {/* Section Start */}
      <section className="py-[90px] text-left overflow-hidden md:py-[85px] sm:py-[60px] bg-white">
        <Container>
          <Row className="items-center">
            <Col lg={5} className="md:mb-[40px] xs:text-center">
              <m.h2
                className="heading-5 font-semibold -tracking-[1px] text-darkgray font-serif mb-0"
                {...fadeIn}
              >
                Why choose us?
              </m.h2>
            </Col>
            <Col lg={7}>
              WE HAVE USED ALL THE MODERN AND ADVANCED TECHNOLOGY MACHINES IN
              ORDER TO PRODUCE THE BEST QUALITY OF RICE IN BOTH OUR PLANTS.
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
      <section className="py-[80px]">
        <Container>
          <div className="row justify-end">
            <div className="col-4 flex justify-end">
              <img
                src="/assets/img/qualityControl/badge-1.webp"
                alt="quality_img"
              />
            </div>
            <div className="col-4 flex justify-end">
              <img
                src="/assets/img/qualityControl/badge-2.webp"
                alt="quality_img"
              />
            </div>
            <div className="col-4 flex justify-end">
              <img
                src="/assets/img/qualityControl/badge-3.webp"
                alt="quality_img"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* Section Start */}
      <section className="pb-[70px] lg:pb-[90px] md:pb-[75px] sm:py-[50px] overflow-hidden">
        <Container className="bg-white py-[30px] shadow-md sm:px-[15px]">
          <m.div className="row justify-center" {...fadeIn}>
            <Col lg={10}>
              <p className="capitalize text-[16px]">
                We Have A Major Supply In{" "}
                <span className="font-semibold text-black">Odisha</span> Region
                Along With That We Also Have A Major Supply In Other States Like{" "}
                <span className="font-semibold text-black"> Uttar Pradesh</span>
                , <span className="font-semibold text-black">Tamil Nadu</span>,{" "}
                <span className="font-semibold text-black">West Bengal</span>,{" "}
                <span className="font-semibold text-black">Jharkhand</span>,
                etc..
              </p>
              <p className="capitalize  text-[16px]">
                Recently, We Have Launched Our Non Basmati Boiled Rice Series In
                Coming Years, We Have A Target To Launch More Varieties Of
                Boiled Rice.
              </p>
              <p className="capitalize  text-[16px]">
                The Focus On Quality Was Our Topmost Priority Right From The
                First Day Of The Production. We Have A Special Assigned Team
                Where All The Required Parameters Of Quality Are Checked And
                Then Passed On. Thats The Reason We Are Still Standing As One Of
                The Leading Brands(gopal Bhog) In The Market From Last 20 Years.
              </p>
            </Col>
          </m.div>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-white pb-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row>
            <Col lg={5} md={6} className="mt-32 md:mt-0 sm:mb-[30px]">
              <h2 className="heading-5 font-medium font-serif text-darkgray w-[90%] mb-[30px]  lg:w-full sm:mb-[15px]">
                WE ARE ONE OF THE{" "}
                <span className="font-semibold">
                  TOP COMPANIES IN MANUFACTURING OF
                </span>{" "}
                PUFFED RICE IN EASTERN INDIA
              </h2>
              {/*<Link
                to=""
                className="mt-[15px] text-[#969896] text-xmd font-serif hover:text-[#969896]"
              >
                More services
                <i className="line-icon-Arrow-OutRight text-[40px] inline-block ml-[15px] align-middle"></i>
  </Link>*/}
            </Col>
            <Col
              lg={{ offset: 1 }}
              md={6}
              className="mb-[30px] mt-[108px] md:mt-0"
            >
              <ProgressBar
                theme="progressbar-style-01 marketing-agency-progressbar"
                trailColor="transparent"
                data={ProgressBarData01}
                height="8px"
                color="#71b100"
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}
    </div>
  );
};

export default QualityControl;
