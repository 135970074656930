import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// Components

import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";

const FooterStyle03 = (props) => {
  return (
    <Footer
      theme={props.theme}
      className={`${
        props.className ? ` ${props.className}` : ""
      } footer-style-03`}
    >
      <div className="py-[6%] lg:py-[8%] md:py-[50px] md:pb-0 xs:py-[50px]">
        <Container>
          <Row className="justify-between md:justify-start">
            <Col
              lg={{ span: 3, order: 0 }}
              sm={{ span: 6, order: 1 }}
              className="md:mb-[40px] xs:mb-[25px]"
            >
              <Link
                aria-label="link"
                to="/"
                className="sm:flex sm:justify-start"
              >
                <img src={props.logo} alt="logo" />
              </Link>
              <p className="mt-[25px] md:w-9/12 xs:w-full">
                At Narayan Group of Companies, we pride ourselves on delivering
                exceptional rice crafted with expertise and passion.
              </p>
            </Col>
            <FooterMenu
              data={FooterData.slice(0, 2)}
              lg={{ span: 2, order: 0 }}
              md={6}
              sm={{ span: 6, order: 2 }}
              className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
              titleClass="capitalize leading-[20px] mb-[25px]"
            />
            <Col
              xl={{ span: 3 }}
              lg={{ span: 4, order: 0 }}
              sm={{ span: 6, order: 3 }}
              md={5}
            >
              <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                Get In Touch
              </span>
              <p className="w-[85%] mb-[15px]">
                NH 215, in front of MSP iron plant, Hundula, Odisha 758031
              </p>
              <div>
                <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                <a href="tel:+917008249607">+91 7008249607</a>
              </div>
              <div>
                <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                <a aria-label="mail" href="mailTo:marketing@ngc.com">
                  marketing@ngc.com
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="py-[15px] border-t border-[#fff] text-white font-semibold"
        style={{ backgroundColor: "#24443593" }}
      >
        <div className="flex px-[150px] justify-between flex-wrap sm:justify-center sm:p-0">
          <small>
            <p className="mb-0 xs:text-center">
              &copy; {new Date().getFullYear()} Narayan Group Of Companies | All
              Rights Reserved.{" "}
            </p>
          </small>

          <small>
            <p className="flex gap-1 mb-0 xs:text-center">
              Developed by :
              <a
                aria-label="teckat"
                className="flex gap-1 text-white underline underline-offset-4 flex items-center pl-1"
                rel="noreferrer"
                target="_blank"
                href="https://www.teckat.com/"
              >
                <img
                  src="/assets/img/footer/teckat-logo.png"
                  width={25}
                  alt="logo"
                  className="h-[25px]"
                />
                <span className="text-[#edf9e4]">Teckat.com</span>
              </a>
            </p>
          </small>
        </div>
      </div>
    </Footer>
  );
};

FooterStyle03.defaultProps = {
  data: FooterData,
  logo: "/assets/img/header/logo.webp",
};

FooterStyle03.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle03);
