const FooterData = [
  {
    title: "quick links",
    submenu: [
      {
        title: "About us",
        link: "/about-us",
      },
      {
        title: "Quality Control",
        link: "/quality-control",
      },
      {
        title: "Contact us",
        link: "/contact",
      },
      {
        title: "From Field To Doorstep",
        link: "/from-farm-to-home",
      },
    ],
  },
  // {
  //   title: "products",
  //   submenu: [
  //     {
  //       title: "Client support",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "Pricing packages",
  //       link: "/page/pricing-packages",
  //     },
  //     {
  //       title: "Company history",
  //       link: "/page/our-story",
  //     },
  //     {
  //       title: "Our process",
  //       link: "/page/our-process",
  //     },
  //   ],
  // },
];

export default FooterData;
