import React from "react";
import FooterStyle03 from "../FooterMain/FooterStyle03";

const FooterMain = () => {
  return (
    <>
      {/* Footer Start */}
      <FooterStyle03 theme="dark" className="bg-[#244435] text-[#edf9e4] gym-fitness-footer" />
      {/* Footer End */}
    </>
  );
};

export default FooterMain; 
