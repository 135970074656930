const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Products",
    link: "/products",
  },
  {
    title: "Quality Control",
    link: "/quality-control",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
  {
    title: "From Field To Doorstep",
    link: "from-farm-to-home",
  },
];

export default HeaderDataAlpha;
