import React from "react";
import Banner from "../../Components/Banner/Banner";
import { Parallax } from "react-scroll-parallax";
import { Col, Container, Row } from "react-bootstrap";
import Overlap from "../../Components/Overlap/Overlap";
import ProcessStep from "../../Components/ProcessStep/ProcessStep";
import Atropos from "atropos/react";
import { ProcessStepData01 } from "../../Components/ProcessStep/ProcessStepData";
import { fadeIn } from "../../Functions/GlobalAnimations";

const FromFarmToHome = () => {
  return (
    <div>
      <Banner
        img="/assets/img/Banner/farm-to-home.webp"
        title="From Field To Doorstep"
        description="We are harvesting fine grains for your daily food !"
      />
      {/* Parallax Scrolling Start */}
      <div className="pt-[50px] pb-[632px] lg:pt-[95px] lg:pb-[600px] md:pt-[70px] md:pb-[400px] sm:pt-[50px] sm:py-[70px] overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[100px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{ backgroundImage: `url(/assets/img/webp/our-story-bg.webp)` }}
        ></Parallax>
        <Container>
          <Row xs={1} className="justify-center">
            <Col xl={8} lg={10} className="text-center">
              <div className="w-[40px] h-[2px]  transform bg-[#71b100] rotate-90 my-[30px] inline-block"></div>
              <h3 className="font-serif font-medium text-darkgray -tracking-[1px] relative mb-0">
                A natural process involved, with{" "}
                <span className="text-gradient bg-[#71b100] font-semibold">
                  an aim
                </span>{" "}
                to deliver{" "}
                <span className="text-gradient bg-[#71b100] font-semibold">
                  quality rice
                </span>
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="pb-[130px] lg:pb-[95px] md:pb-[70px] sm:py-[50px]">
        <Container className="md:mb-[70px] sm:mb-20">
          <Row className="justify-center">
            <Overlap value={70} className="row">
              <Col className="col-12 col-md-12 z-0 p-[0px] lg:p-[15px] md:p-[0px] text-center">
                <span className="mb-[-60px] relative uppercase text-[11rem] leading-[11rem] md:text-[9rem] sm:hidden font-serif text-center none md:block font-bold opacity-10 -z-[1]">
                  our story
                </span>
                <Atropos
                  highlight={false}
                  stretchX={0}
                  stretchY={0}
                  rotateTouch={false}
                  className={`my-atropos -top-[7%] md:top-0 w-full`}
                >
                  <img
                    loading="lazy"
                    src="/assets/img/farmToHome/banner-card.webp"
                    className="relative z-10 rounded-[6px]"
                    alt=""
                    data-no-retina=""
                  />
                </Atropos>
              </Col>
            </Overlap>
          </Row>
        </Container>
        <Container>
          <Row className="justify-center">
            <Col md={12} sm={8}>
              <ProcessStep
                theme="process-step-style-05 md:px-0 sm:mb-8"
                className="our-story-process-step-style overflow-hidden sm:inline-block text-center text-[#fff]"
                data={ProcessStepData01}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </div>
  );
};

export default FromFarmToHome;
