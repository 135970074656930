import React, { useState } from "react";

import { Header, HeaderNav, Menu } from "../../Components/HeaderMain/Header";

// Libraries
import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HeaderMain.scss";
import { useLocation } from "react-router-dom";

const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ md: true }}
        type="reverse-scroll"
        className={`${currentPath !== "/" ? "homeless_header" : ""}`}
      >
        <HeaderNav
          theme="dark"
          expand="lg"
          className="py-[0px] px-[35px] md:px-0 sm:px-0 md:py-[20px]"
          fluid="fluid"
          containerClass="sm:p-0"
        >
          <Col className="col-5 sm:w-[70%] col-lg-2 ps-lg-0 me-auto me-lg-0">
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="220"
                  height="60"
                  loading="lazy"
                  src="/assets/img/header/logo-reverse.webp"
                  data-rjs="/assets/img/header/logo@2x.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/header/logo-reverse.webp"
                  data-rjs="/assets/img/header/logo-reverse@2x.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/header/logo-reverse.webp"
                  data-rjs="/assets/img/header/logo@2x.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`md:mr-[15px] ${!open ? "collapsed" : ""}`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="col-auto col-lg-7 justify-center gymfitness-header-menu"
            in={open}
          >
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
          <Col className="header_btn_container col-auto col-lg-3 text-end hidden-xs px-lg-0 sm:pr-[15px] xs:!text-center d-flex gap-[20px] items-center justify-end">
            <div className="header_btn bg[#2ED47C]">
              <button
                className="d-flex items-center justify-center gap-[10px] font-bold text-[14px] whitespace-nowrap  !bg-[#2ED47C]"
                onClick={() => {
                  window.location.href = "https://wa.me/+917008249607";
                }}
              >
                Whatsapp Us
                <i className="fa-brands fa-whatsapp text-[20px]"></i>
              </button>
            </div>
            <a
              aria-label="link for top"
              href="mailto:marketing@ngc.com"
              className="header_call text-md text-[#232323] font-serif font-medium md:text-[#232323] hover:text-[#2323235d]"
            >
              <i class="fa-regular fa-envelope"></i>
            </a>
            <a
              aria-label="link for top"
              href="tel:+917008249607"
              className="header_call text-md text-[#232323] font-serif font-medium md:text-[#232323] hover:text-[#2323235d] whitespace-nowrap"
            >
              +91 7008249607
            </a>
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
