import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./NarayanGroupOfCompanies/Context/Context";

// Components
import ScrollToTopButton from "./NarayanGroupOfCompanies/Components/ScrollToTop";

import HeaderAlpha from "./NarayanGroupOfCompanies/Components/HeaderMain";
import FooterAlpha from "./NarayanGroupOfCompanies/Components/FooterMain";
import NotFoundPage from "./NarayanGroupOfCompanies/Pages/404";
import QualityControl from "./NarayanGroupOfCompanies/Pages/QualityControl/QualityControl";
import FromFarmToHome from "./NarayanGroupOfCompanies/Pages/FarmToHome/FromFarmToHome";

const Header = React.lazy(() =>
  import("./NarayanGroupOfCompanies/Components/HeaderMain/Header").then(
    (module) => ({
      default: module.Header,
    })
  )
);

// pages

const HomePage = lazy(() => import("./NarayanGroupOfCompanies/Pages/Home"));
const AboutUs = lazy(() => import("./NarayanGroupOfCompanies/Pages/AboutUs"));
const Products = lazy(() => import("./NarayanGroupOfCompanies/Pages/Products"));
const Contact = lazy(() => import("./NarayanGroupOfCompanies/Pages/ContactUs"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Theme/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* Header Start */}
            <Header topSpace={{ md: false }} type="reverse-scroll">
              <HeaderAlpha />
            </Header>

            {/* Header End */}
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#27ae60" }} />}
                  />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="products" element={<Products />} />
                  <Route path="quality-control" element={<QualityControl/>}/>
                  <Route path="from-farm-to-home" element={<FromFarmToHome />}/>


                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            {/* Footer Start */}

            <FooterAlpha />
            {/* Footer End */}
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
