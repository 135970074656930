const ProcessStepData01 = [
    {
        icon: "line-icon-Idea-5",
        title: "Preparing & Planting",
        content: "Level field, sow seeds, plow. Seeds planted by hand or machines after washing.",
    },
    {
        icon: "line-icon-Fountain-Pen",
        title: "Harvest",
        content: "After three months, the paddy is ready for harvest. Water is drained, and grains are dried to ripen.",
    },
    {
        icon: "line-icon-Loading-2",
        title: "Hulling & Milling",
        content: "The rice grains undergo a rigorous process, removing outer hulls.",
    },
    {
        icon: "line-icon-Juice",
        title: "Packaging",
        content: "After drying, the rice is packed and sent to local markets.",
    },
]

const ProcessStepData02 = [
    {
        title: "Project research",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    },
    {
        title: "Sketches and design",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    },
    {
        title: "Seleted concept",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    }
]

const ProcessStepData03 = [
    {
        title: "RESEARCH",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "SKETCHES",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "CONCEPT",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "PRESENTATION",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
]

const ProcessStepData04 = [
    {
        title: "RESEARCH PROJECT",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "FIND PROBLEM",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "START OPTIMIZE",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "REACH TARGET",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
]

const ProcessStepData05 = [
    {
        title: "Start market research",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    },
    {
        title: "Discussion of the idea",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    },
    {
        title: "Production planning",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    }
]

const ProcessStepData06 = [
    {
        title: "Innovative business",
        content: "Easy to customize",
    }
]


export { ProcessStepData01, ProcessStepData02, ProcessStepData03, ProcessStepData04, ProcessStepData05, ProcessStepData06 }