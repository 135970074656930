import React from "react";
// import { Container } from "react-bootstrap";

const Banner = (props) => {
  return (
    <div>
      {/* Section Start */}
      <section
        className="bg-cover bg-center relative"
        // style={{ backgroundImage: `url(${props.img})` }}
      >
        {/* <Container className="h-[300px] sm:h-[250px] flex justify-center sm:justify-end sticky z-10">
          <Row xs={1} md={2} className="items-end justify-end sm:flex-col">
            <Col className="p-0 sm:!px-[15px] sm:mb-[20px]">
              <h1 className="text-xmd !leading-[20px] pl-[90px] pr-[15px] text-white font-serif mb-0 relative sm:pl-[55px] md:mb-0 sm:mb-[20px]">
                <span className="h-[1px] absolute top-[12px] left-0 w-[70px] bg-white sm:w-[40px] sm:top-1/2"></span>
                {props.title}
              </h1>
            </Col>
            <Col className="p-0 sm:!px-[15px]">
              <h4 className="font-medium text-white font-serif mb-0">
                {props.description}
              </h4>
            </Col>
          </Row>
        </Container> */}
        <img src={props.img} alt="" />
        {/* bg-[#262b35] */}
        {/* <div class="absolute h-full w-full top-0 left-0 opacity-60 bg-[#262b35]"></div> */}
      </section>
      {/* Section End */}
    </div>
  );
};

export default Banner;
